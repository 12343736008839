import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import TwoCents from "../../components/SingleCase/2Cents"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForTwoCents from "../../information/Cases/dataForTwoCents"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class CartoveraPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForTwoCents

    return (
      <section className="singleProject">
        <SEO
          title="2Cents - Legacy Social Network - Web App Development Case — OS-System"
          description="Development Case of Web & Mobile Application for Social Network - 2Cents - Social Media Platform. How to redo a legacy social app after other developers."
          canonical={`${mainUrl}/cases/2cents/`}
        />
        <Layout showFormPoint={100}>
          <TwoCents data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
