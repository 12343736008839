import React from 'react'
import SpecificComponent from './SpecificComponent'
import BasicSectionComponent from './BasicSectionComponent'
import BasicImageSectionComponent from './BasicImageSectionComponent'

// import OverviewComponent from '../OverviewComponent';

import './2Cents.scss'

const TwoCents = ({ data }) => {
  return (
    <div className='two-cents-container'>
      <section className='first-section'>
        <div className='container'>
          <div className='header-wrapper'>
            <div className='logo'>
              <img src={data.images.logo} alt='2cents logo' />
            </div>
            <div className='title-wrapper'>
              <h1 className='twocents-header'><span className='twocents-header-bluetext'>Case Studies:</span><br/>Legacy Social Network<br/>Web App Development</h1>
            </div>
          </div>
        </div>
      </section>

      <section className='content-section'>
        <div className='container'>
          <div className="row">
            <div className='col-sm-8 col-md-7 col-lg-8'>
              <h2 className='project-overview-title'>Project overview</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-8 col-md-7 col-lg-8'>

              <BasicSectionComponent
                title={data.overview.about.title}
                content={data.overview.about.content}
                sectionClasses={['client-background']}
              />
            </div>
            <div className='col-xs-7 col-sm-4 col-md-4 col-lg-3 col-md-offset-1 col-lg-offset-1 overview'>
              <SpecificComponent {...data} />
            </div>
            <div className='col-sm-8 col-md-7 col-lg-8'>
              <BasicSectionComponent
                title={data.overview.challenge.title}
                content={data.overview.challenge.content}
                sectionClasses={['business-challenge']}
              />
            </div>
            <div className='col-lg-12'>
              <BasicImageSectionComponent
                imgUrl={data.images.firstImage}
                sectionClasses={['first-image']}
              />

              <BasicSectionComponent
                title={data.solution.title}
                content={data.solution.content}
                titleTag='h2'
                sectionClasses={['solution']}
              />

              <BasicImageSectionComponent
                imgUrl={data.images.secondImage}
                sectionClasses={['second-image']}
              />

              <BasicSectionComponent
                titleTag='h2'
                title={data.delivered.title}
                content={data.delivered.content}
                sectionClasses={['value-delivered']}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TwoCents
