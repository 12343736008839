import React from "react"
import PropTypes from "prop-types"

const sectionDefaultClass = "specific-image-section"

const BasicImageSectionComponent = props => {
  const { sectionClasses, imgUrl, imgAlt } = props

  const renderClasses = values => {
    return !values.find(elem => elem === sectionDefaultClass)
      ? [sectionDefaultClass, ...values].join(" ")
      : values.join(" ")
  }

  return (
    <section className={renderClasses(sectionClasses)}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img src={imgUrl} alt={imgAlt} />
          </div>
        </div>
      </div>
    </section>
  )
}

BasicImageSectionComponent.defaultProps = {
  imgAlt: "section image",
  sectionClasses: [sectionDefaultClass],
}

BasicImageSectionComponent.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  sectionClasses: PropTypes.arrayOf(PropTypes.string),
}

export default BasicImageSectionComponent
