import React from "react"
// import renderHTML from 'react-render-html';
import PropTypes from "prop-types"

const sectionDefaultClass = "specific-section"

const BasicSectionComponent = props => {
  const { title, content, titleTag, sectionClasses, contentClasses } = props

  const renderTitle = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `<${titleTag} className="section-title">${title}</${titleTag}>`,
        }}
      />
    )
  }

  const renderClasses = (values, withDefault) => {
    return withDefault && !values.find(elem => elem === sectionDefaultClass)
      ? [sectionDefaultClass, ...values].join(" ")
      : values.join(" ")
  }

  const renderContent = () => {
    return Array.isArray(content) ? (
      content.map((value, key) => <p className="mb-4" key={key}>{value}</p>)
    ) : (
      <p>{content}</p>
    )
  }

  return (
    <section className={`${renderClasses(sectionClasses, true)}`}>
      {renderTitle()}
      <div className="row">
        <div className={renderClasses(contentClasses)}>{renderContent()}</div>
      </div>
    </section>
  )
}

BasicSectionComponent.defaultProps = {
  titleTag: "h3",
  sectionClasses: [sectionDefaultClass],
  contentClasses: ["col-md-12"],
}

BasicSectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  titleTag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  sectionClasses: PropTypes.arrayOf(PropTypes.string),
  contentClasses: PropTypes.arrayOf(PropTypes.string),
}

export default BasicSectionComponent
