import React from "react"

const SpecificComponent = props => {
  return (
    <ul>
      {props.overview.specific.map(item => (
        <li key={item.type}>
          <i className={`fa ${item.icon}`} aria-hidden="true"></i>
          <h4>{item.type}</h4>
          <span>
            {item.content.map((elem, i) => {
              if (typeof elem === "string") {
                const separator = i + 1 < item.content.length ? ", " : ""
                return `${elem}${separator}`
              } else if (elem instanceof Object) {
                const content =
                  elem.count !== 0 || elem.count !== ""
                    ? `${elem.count} ${elem.type}`
                    : elem.type
                return <div key={elem.type}>{content}</div>
              }
            })}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default SpecificComponent
