import { First, Header, Logo, Second } from "../../images/SingleCase/2Cents"

const dataForTwoCents = {
  type: "project",
  smart: true,
  classComponent: "TwoCents",
  nextLink: "cases/service-doc/",
  prevLink: "cases/cartovera/",
  data: {
    overview: {
      title: "Project overview",
      intro: {
        content: [
          "Despite being occupied by big names, social media are still one of the promising markets. The rapid evolution of technology stimulates the openings for new opportunities in the industry and the realization of new ideas. Today, we want to cover one of our latest projects and show you how we made a modern social platform out of the already existed legacy code.",
          "When creating social media it is important to evaluate the unique functionality to attract users to come and try your platform. Despite the fact that there are lots of social platforms on the web, there are promising opportunities for social network development if you have a unique value to provide your users with.",
        ],
      },
      about: {
        title: "Client background",
        content:
          "A client had a long-term legacy project of a responsible social media platform, with the strong protection of its users’ data and ad-free subscription-based policy terms. However, due to the lack of expertise in the field of web & mobile application development, there was a lot of key functionality missing for the platform to successfully go into production.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The client was looking for a dedicated development team which could take the outdated legacy code of the already existing website and transform it into fully functional web & mobile social media application. Having worked with different web development services providers, the customer still had not achieved a working product, despite already spending the budget for the project.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web", "iOS", "Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "React.js",
            "React Native",
            "PHP",
            "Laravel",
            "Bitrise CI/CD",
            "Firebase",
            "Stripe",
            "MySQL",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 4,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "6 months (ongoing)",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Individual entrepreneur"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      content: [
        "Given the fact that the client required to achieve a working platform out of the long-term legacy code project, we decided to give the revision of the code itself at first to give a client a vision of what parts of the project could be saved and what should be completely reworked. After the revision, we have evaluated that most of the project’s already existed code was outdated and required a complete rework to have the client’s desired features successfully function on the platform.",
        "Because of the constant changing between contractors, the already existing code of the customer’s platform was messy, hard to maintain, and made it impossible to add new features. After finalizing a review of the code to the client and describing the importance of rewriting the code we began the refactoring process of the web application.",
        "We have evaluated the optimal workflow for the project based on the Kanban methodology, and later on, when the product has finally met the market, we moved to Agile methodology while implementing additional features and functionality for the app.",
        "OS-System has provided the customer with a dedicated team of IT specialists in the field of web and mobile development, project management and quality assurance to cover all the needs on all the levels of the social media platform project implementation. After the successful release of the app for both web and mobile devices, we continue to provide the customer with an ongoing support of the project.",
      ],
    },
    delivered: {
      title: "Value delivered",
      content:
        "OS-System’s team has built trustworthy partnership relations with a client because of the strong mutual desire to deliver high-quality social media application for both web and mobile devices. Due to the requirement of the cross-platform and web presence of the app, we successfully moved the project to React and React Native technologies and released the MVP version in the short terms while constantly adding new features and improving the secureness of the platform. ",
    },
    images: {
      firstImage: First,
      secondImage: Second,
      headerCircles: Header,
      logo: Logo,
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/2cents",
}

export default dataForTwoCents
